<template>
  <div>
    <FavoritesAppbar/>
    <br/><br/>
    <div class="mt-8 mx-auto px-2" style="max-width:800px;">
     
      <PrayersList/>
      <JoinTelegram class="mt-4"/>
      <SharePopup/>
    </div>
  </div>
</template>

<script>
  const FavoritesAppbar = () => import('@/components/Appbars/FavoritesAppbar.vue')
  const PrayersList = () => import('@/components/PrayersList.vue')
  const SharePopup = () => import('@/components/App/SharePopup.vue')
  const JoinTelegram = () => import('@/components/JoinTelegram.vue')
  import { mapActions } from 'vuex'

  export default {
    name: 'Favorites',
    methods: {
      ...mapActions('storeApp', ['loadFav']),
      ...mapActions('storeShare', ['setShareVisible'])
    },
    components: {
      FavoritesAppbar,
      PrayersList,
      SharePopup,
      JoinTelegram,
    },
    created() {
      this.loadFav()
      this.setShareVisible(false)
      this.$gtag.pageview(this.$route)
    }
  }
</script>
