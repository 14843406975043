<template>
  <div>
    <PrayerAppbar :title="prayer != undefined ? prayer.name : ''" :id="prayer.id"/>
    <br/><br/>
    <div class="mt-8 mx-auto px-4" style="max-width:800px;">
      <Dedicated :prayer="prayer" v-if="showDedicated"/>
      <v-card
        class="mt-4"
        elevation="2">
        <v-card-text class="caption">{{ prayer.description }}</v-card-text>
      </v-card>

      <div class="mt-6">
        <span class="amisraelive-font" v-html="prayer.prayerData"></span>
      </div>

      <div v-if="prayer.credit" class="text-center caption mt-8"> קרדיט ל
        <br/>
        <v-btn class="text-capitalize caption" @click="credit(prayer.credit)" text small>
          {{ prayer.credit }}
        </v-btn>
      </div>
      <br/>
      <Dedicated :prayer="prayer" v-if="showDedicated"/>
      <br/>
      <DedicatedCreator :prayer="prayer"/>
      
      <SharePrayer :prayer="prayer"/>

      <JoinTelegram class="mt-10"/>

      <MorePrayers/>

      <v-divider class="mt-8"></v-divider>

      <!-- <SupportUs/> -->
      
      <ShareApp/>
      
      <Footer/>

      
    </div>
  </div>
</template>

<script>
  const PrayerAppbar = () => import('@/components/Appbars/PrayerAppbar.vue')
  const SharePrayer = () => import('@/components/App/SharePrayer.vue')
  const ShareApp = () => import('@/components/App/ShareApp.vue')
  const Footer = () => import('@/components/App/Footer.vue')
  const Dedicated = () => import('../components/Dedicated.vue')
  const DedicatedCreator = () => import('../components/DedicatedCreator.vue')
  const MorePrayers = () => import('../components/MorePrayers.vue')
  const JoinTelegram = () => import('@/components/JoinTelegram.vue')
  //import SupportUs from '@/components/App/SupportUs.vue'
  


  export default {
    name: 'Prayer',
    data() {
      return {
        prayer: {},
        showDedicated: false
      }
    },
    methods: {
      loadPrayer() {
        try {
          // let loadedPrayer =  require('@/assets/prayers/' + this.$route.params.id + '.json')
          // this.prayer = loadedPrayer

          fetch('https://amisraelive.com/prayers/' + this.$route.params.id + '.json')
                    .then(response => response.json())
                    .then(data => {
                      this.prayer = data
                    })
                    .catch(() => {
                      let loadedPrayer =  require('@/assets/prayers/' + this.$route.params.id + '.json')
                      this.prayer = loadedPrayer
                    })

        } catch(e) {
          window.open('/',"_self")
        }
      },
      credit(value) {
        window.open(value,"_blank")
      },
      setDedicated() {
        let path = this.$route.fullPath.toLowerCase()
        if(path.includes('dedicated/') && path.includes('to/') && this.$route.params.type && this.$route.params.name) {
          this.showDedicated = true
        } else {
          this.showDedicated = false
        }
        
      }
    },
    components: {
      PrayerAppbar,
      SharePrayer,
      ShareApp,
      Footer,
      Dedicated,
      DedicatedCreator,
      MorePrayers,
      JoinTelegram
      //SupportUs
    },
    created() {
      this.loadPrayer()
      this.setDedicated()
      this.$gtag.pageview(this.$route)
      this.$gtag.event('Prayer - ' + this.$route.params.id, { method: 'Google' })
    }
  }
</script>
