import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Favorites from '../views/Favorites.vue'
import Prayer from '../views/Prayer.vue'
import TehilimDay from '../views/TehilimDay.vue'
import Tehilim from '../views/Tehilim.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: Favorites
  },
  {
    path: '/tehilimday',
    name: 'TehilimDay',
    component: TehilimDay
  },
  {
    path: '/tehilim',
    name: 'Tehilim',
    component: Tehilim
  },
  {
    path: '/tehilim/select/:selected/book/:book',
    name: 'Tehilim',
    component: Tehilim
  },
  {
    path: '/tehilim/select/:selected/book/:book/dedicated/:type/to/:name',
    name: 'Tehilim',
    component: Tehilim
  },
  {
    path: '/tehilim/*',
    name: 'Tehilim',
    component: Tehilim
  },
  {
    path: '/prayer/:id',
    name: 'Prayer',
    component: Prayer
  },
  {
    path: '/prayer/:id/dedicated/:type/to/:name',
    name: 'Prayer',
    component: Prayer
  },
  {
    path: '/prayer/:id/*',
    name: 'Prayer',
    component: Prayer
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '*',
    name: 'Home',
    component: Home
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
