<template>
    <div>
        <CustomAppbar :title="'תהילים'"/>
        <br/><br/>
        <div class="mt-8 mx-auto px-4" style="max-width:800px;">
            <Dedicated :prayer="prayer" v-if="showDedicated"/>
            <div class="text-center mx-auto">
                <v-radio-group v-model="selectedRadio" row>
                    <v-radio
                        class="mx-auto"
                        v-for="(item, i) in options"
                        :key="i"
                        :label="item"
                        :value="item"
                        @change="changeRadio(item)">
                    </v-radio>
                </v-radio-group>

                <v-select
                    class="mt-4 mx-auto"
                    :items="items"
                    :label="'בחירת ' + selectedRadio"
                    color="primary"
                    @change="selectItem"
                    v-model="book"
                    dense
                    hide-details
                    outlined>
                </v-select>  
            </div>

            <v-divider class="mt-8"></v-divider>


            <div class="text-center mt-4" v-if="book.length > 0">
                <h3 class="primary--text" v-if="selectedRadio == 'ספר'">תהילים  {{ book }}</h3>
                <h3 class="primary--text" v-else-if="selectedRadio == 'יום'">תהילים ל{{ book }}</h3>
                <h3 class="primary--text" v-else-if="selectedRadio == 'פרק'">תהילים פרק {{ book }}</h3>
                <h3 class="primary--text" v-else-if="selectedRadio == 'תאריך'">תהילים ליום {{ book }} לחודש</h3>
            </div>

            <div class="mt-6 mb-6" v-for="(item, i) in tlm" :key="i">
                <div v-if="item.perek != 'קיט'">
                    <h4 class="mt-3">{{ item.perek }}</h4>
                    <div v-for="(txt, j) in item.text" :key="j">{{ txt }}</div>
                </div>

                <div v-else>
                    <h4 class="mt-3" v-if="i == 0 || tlm[i-1].perek != 'קיט'">{{ item.perek}}</h4>
                    <h4 class="mt-2">{{ item.letter }}</h4>
                    <div v-for="(txt, h) in item.text" :key="h">{{ txt }}</div>
                </div>
            </div>

            <TehilimShareCard :tehilimData="tehilimData" class="mt-12"/>

            <ShareTehilim class="mt-12"/>

            <JoinTelegram class="mt-10"/>

            <MorePrayers/>

            <v-divider class="mt-8"></v-divider>
            
            <ShareApp/>
            
            <Footer/>

        </div>

    </div>
</template>

<script>
import tehilim from '@/scripts/tehilim.js'
import hebTehilim from '@/scripts/heb-tehilim.js'
const CustomAppbar = () => import('@/components/Appbars/CustomAppbar.vue')
const ShareTehilim = () => import('@/components/App/ShareTehilim.vue')
const MorePrayers = () => import('@/components/MorePrayers.vue')
const ShareApp = () => import('@/components/App/ShareApp.vue')
const Footer = () => import('@/components/App/Footer.vue')
const Dedicated = () => import('@/components/Dedicated.vue')
const TehilimShareCard = () => import('@/components/TehilimShareCard.vue')
const JoinTelegram = () => import('@/components/JoinTelegram.vue')

export default {
    data() {
        return {
            selectedRadio: 'ספר',
            options: ['ספר', 'יום', 'פרק', 'תאריך'],
            book: '',
            perek: '',
            date: '',
            day: '',
            items: [],
            tlm: '',
            showDedicated: false,
            prayer: {
                name: 'תהילים'
            }
        }
    },
    computed: {
        tehilimData: function() {
            let tehilimData = {
                book: this.book,
                selected: this.selectedRadio,
            }
            return tehilimData
        }
    },
    methods: {
        changeRadio(item) { 
            this.selectedRadio = item
            this.initItems()
        },
        initItems() {
            this.tlm = ""
            this.book = ""
            switch(this.selectedRadio) {
                case 'ספר':
                    this.items = hebTehilim.booksTitle
                    break;
                case 'יום':
                    this.items = hebTehilim.daysTitle
                    break;
                case 'פרק':
                    this.items = tehilim.getArrOfPerek(0,150)
                    break;
                case 'תאריך':
                    this.items = tehilim.getArrOfPerek(0,30)
                    break;
                default: 
                    this.items = []
            }
        },
        selectItem(item) {
            if(this.selectedRadio == 'ספר') {
                this.tlm = tehilim.getTehilimBook(tehilim.convertBookStrToBookNumber(item))
            } else if(this.selectedRadio == 'יום') {
                this.tlm = tehilim.getTehilimWeek(tehilim.convertDayStrToBookNumber(item))
            } else if(this.selectedRadio == 'פרק') {
                this.tlm = tehilim.getPerekTehilim(tehilim.convertLettersToNumber(item) - 1)
            } else if(this.selectedRadio == 'תאריך') {
                this.tlm = tehilim.getTehilimParts(tehilim.getTehilimPartsDay(item))
            }
        },
        setDedicated() {
            let path = this.$route.fullPath.toLowerCase()
            if(path.includes('dedicated/') && path.includes('to/') && this.$route.params.type && this.$route.params.name) {
                this.showDedicated = true
            } else {
                this.showDedicated = false
            }
        },
        setInitData() {
            let path = this.$route.fullPath.toLowerCase()
            if(path.includes('select/') && path.includes('book/') && this.$route.params.selected && this.$route.params.book) {
                let selected = this.$route.params.selected
                let book = this.$route.params.book
                if(selected && this.options.includes(selected) && book) {
                    this.changeRadio(selected)
                    if(book && this.items.includes(book)) {
                        this.book = book
                        this.selectItem(this.book)
                    } else {
                        this.initItems()
                    }  
                } else {
                    this.initItems()
                }
            } else {
                this.initItems()
            }
            
        }
    },
    components: { 
      CustomAppbar,
      ShareTehilim,
      MorePrayers,
      ShareApp,
      Footer,
      Dedicated,
      TehilimShareCard,
      JoinTelegram
    },
    created() {
        this.setInitData()
        this.setDedicated()
        this.$gtag.pageview(this.$route)
    }
}

</script>