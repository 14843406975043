import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'
import VueSocialSharing from 'vue-social-sharing'
import VueMeta from 'vue-meta'

Vue.use(Vuetify)
Vue.use(VueSocialSharing)
Vue.use(VueMeta)

export default new Vuetify({
    theme: {
        themes: {
            light: {
            primary: '#00A8CD', // #00A8CD
            secondary: '#000000', // #FFFFFF
            fav: '#FEB03C', //#FEB03C 
            accent: '#00C620', // #00C620
            error: '#E53935',
            description: '#7E7E7E'
            },
        },
    },
    rtl: true,
});
