export default {
    letters:  [
        { letter: 'א', value: 1 },
        { letter: 'ב', value: 2 },
        { letter: 'ג', value: 3 },
        { letter: 'ד', value: 4 },
        { letter: 'ה', value: 5 },
        { letter: 'ו', value: 6 },
        { letter: 'ז', value: 7 },
        { letter: 'ח', value: 8 },
        { letter: 'ט', value: 9 },
        { letter: 'י', value: 10 },
        { letter: 'כ', value: 20 },
        { letter: 'ל', value: 30 },
        { letter: 'מ', value: 40 },
        { letter: 'נ', value: 50 },
        { letter: 'ס', value: 60 },
        { letter: 'ע', value: 70 },
        { letter: 'פ', value: 80 },
        { letter: 'צ', value: 90 },
        { letter: 'ק', value: 100 },
        { letter: 'ר', value: 200 },
        { letter: 'ש', value: 300 },
        { letter: 'ת', value: 400 }
    ],

    days: [
        'ראשון',
        'שני',
        'שלישי',
        'רביעי',
        'חמישי',
        'שישי',
        'שבת'
    ],

    booksTitle: ['ספר ראשון', 'ספר שני', 'ספר שלישי', 'ספר רביעי', 'ספר חמישי'],

    daysTitle: ['יום ראשון', 'יום שני', 'יום שלישי', 'יום רביעי', 'יום חמישי', 'יום שישי', 'יום שבת'],

    tehilimDaysMonth: [
        { start: 0,end: 8 },
        { start: 9,end: 16 },
        { start: 17,end: 21 },
        { start: 22,end: 27 },
        { start: 28,end: 33 },
        { start: 34,end: 37 },
        { start: 38,end: 42 },
        { start: 43,end: 47 },
        { start: 48,end: 53 },
        { start: 54,end: 58 },
        { start: 59,end: 64 },
        { start: 65,end: 67 },
        { start: 68,end: 70 },
        { start: 71,end: 75 },
        { start: 76,end: 77 },
        { start: 78,end: 81 },
        { start: 82,end: 86 },
        { start: 87,end: 88 },
        { start: 89,end: 95 },
        { start: 96,end: 102 },
        { start: 103,end: 104 },
        { start: 105,end: 106 },
        { start: 107,end: 111 },
        { start: 112,end: 117 },
        { start: 118,end: 118, part: 1 },
        { start: 118,end: 118, part: 2},
        { start: 119,end: 133 },
        { start: 134,end: 138 },
        { start: 139,end: 143 },
        { start: 144,end: 149 },
    ],

    tehilimDaysWeek: [
        { start: 0,end: 28 },
        { start: 29,end: 49 },
        { start: 50,end: 71 },
        { start: 72,end: 88 },
        { start: 89,end: 105 },
        { start: 106,end: 118 },
        { start: 119,end: 149 }
    ],

    tehilimBooks: [
        { start: 0,end: 40 },
        { start: 41,end: 71 },
        { start: 72,end: 88 },
        { start: 89,end: 105 },
        { start: 106,end: 149 }
    ]
}