const state = {
    networks: [
        { network: 'email', name: 'אימייל', icon: 'mdi-email', color: '#333333' },
        { network: 'whatsapp', name: 'ווטסאפ', icon: 'mdi-whatsapp ', color: '#25d366' },
        { network: 'facebook', name: 'פייסבוק', icon: 'mdi-facebook', color: '#1877f2' },
        { network: 'sms', name: 'סמס', icon: 'mdi-message-processing', color: '#EF6C00' },
        { network: 'telegram', name: 'טלגרם', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
        { network: 'twitter', name: 'טוויטר', icon: 'mdi-twitter', color: '#1da1f2' },
        { network: 'linkedin', name: 'לינקדאין', icon: 'mdi-linkedin', color: '#007bb5' },
        { network: 'skype', name: 'סקייפ', icon: 'mdi-skype', color: '#00aff0' },
        { network: 'reddit', name: 'רדיט', icon: 'mdi-reddit', color: '#ff4500' },
        { network: 'pinterest', name: 'פינטרסט', icon: 'mdi-pinterest', color: '#bd081c' }
    ],
    sharePlatforms: ['email', 'whatsapp', 'telegram', 'facebook', 'twitter', 'sms'],
    shareState: {
        shareVisible: false,
        share: {
            url: '',
            title: '',
            description: ''
        }
    }
    
}

const mutations = {
   setShare(state, share) {
       state.shareState.share.url = share.url
       state.shareState.share.title = share.title
       state.shareState.share.description = share.description
   },
   setShareVisible(state, visible) {
       state.shareState.shareVisible = visible
   }
}

const actions = {
    setShare({ commit }, share) {
        commit('setShare', share)
    },
    setShareVisible({ commit }, visible) {
        commit('setShareVisible', visible)
    }
}

const getters = {
    platforms: (state) => {
        let userSharePlatforms = []
        state.sharePlatforms.forEach(item => {
            state.networks.forEach(network => {
                if(network.network === item) {
                    userSharePlatforms.push(network)
                }
            }) 
        })
        return userSharePlatforms
    },
    share: (state) => {
        return state.shareState.share
    },
    shareState: (state) => {
        return state.shareState
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}