const state = {
    favorites:[]
}

const mutations = {
    addFav(state, id) {
        state.favorites.push(id)
        localStorage.setItem('amisraelive_favorites', JSON.stringify(state.favorites))
    },
    removeFav(state, id) {
        let favorites = state.favorites.filter(favId => favId != id)
        state.favorites = Object.assign([], favorites)
        localStorage.setItem('amisraelive_favorites', JSON.stringify(state.favorites))
    },
    loadFav(state) {
        let fav = JSON.parse(localStorage.getItem('amisraelive_favorites') || '[]')
        state.favorites = Object.assign([], fav)
    }
}

const actions = {
    addFav({ commit }, id) {
        commit('addFav', id)
    },
    removeFav({ commit }, id) {
        commit('removeFav', id)
    },
    loadFav({ commit }) {
        commit('loadFav')
    },
}

const getters = {
    favorites: (state) => {
        return state.favorites
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}