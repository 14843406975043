<template>
  <div>
    <MainAppbar/>
    <br/> <br/>
    <div class="mt-8 mx-auto px-2" style="max-width:800px;">
     
      <InstallApp class="mr-2 ml-2"/>
      <TimesData class="mt-4"/>
      <v-divider class="mt-4"></v-divider>
      <DayCard/>
      <PrayersList/>
      <SharePopup/>

      <v-divider class="mt-8"></v-divider>

      <JoinTelegram class="mt-2"/>

      <ShareApp/>
      <Footer/>
    </div>
  </div>
</template>

<script>
  const MainAppbar = () => import('@/components/Appbars/MainAppbar.vue')
  const InstallApp = () => import('@/components/Cards/InstallApp.vue')
  const PrayersList = () => import('@/components/PrayersList.vue')
  const SharePopup = () => import('@/components/App/SharePopup.vue')
  const ShareApp = () => import('@/components/App/ShareApp.vue')
  const Footer = () => import('@/components/App/Footer.vue')
  const TimesData = () => import('@/components/TimesData.vue')
  const DayCard = () => import('@/components/DayCard.vue')
  const JoinTelegram = () => import('@/components/JoinTelegram.vue')

  import { mapActions } from 'vuex'



  export default {
    name: 'Home',
    data() {
      return {
        
      }
    },
    methods: {
      ...mapActions('storeApp', ['loadFav']),
      ...mapActions('storeShare', ['setShareVisible']),
    },
    components: {
      MainAppbar,
      InstallApp,
      PrayersList,
      SharePopup,
      ShareApp,
      Footer,
      TimesData,
      DayCard,
      JoinTelegram,
    },
    created() {
      window.scrollTo(0,0)
      this.loadFav()
      this.setShareVisible(false)
      this.$gtag.pageview(this.$route)
    }
  }
</script>
