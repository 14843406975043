import hebTehilim from '@/scripts/heb-tehilim.js'
import TehilimJSON from '@/assets/prayers/tehilim.json'

export default {

    convertLettersToNumber(letters) {
        let lettersArr = letters.split("")
        let l1 = lettersArr[0]
        let l2 = (lettersArr.length > 1) ? lettersArr[1] : ''
        let l3 = (lettersArr.length > 2) ? lettersArr[2] : ''
       
        let l1Number = this.getValueFromLetter(l1)
        let l2Number = this.getValueFromLetter(l2)
        let l3Number = this.getValueFromLetter(l3)

        return l1Number + l2Number + l3Number
    },

    getValueFromLetter(letter) {
        let value = 0
        if(letter) {
            value = hebTehilim.letters.find(item => letter == item.letter).value
        } 
        
        return value
    },

    convertNumberToLetters(number) {
        let num1 = parseInt(number / 100) * 100
        let num2 = parseInt((number - num1) / 10) * 10
        let num3 = Math.abs(number - num1 - num2)
       
        let l1Str = this.getLetterFromValue(num1)
        let l2Str = this.getLetterFromValue(num2)
        let l3Str = this.getLetterFromValue(num3)

        let lettersToReturn = l1Str + l2Str + l3Str

        lettersToReturn = lettersToReturn.replace('יה', 'טו')
        lettersToReturn = lettersToReturn.replace('יו', 'טז')

        return lettersToReturn
    },

    getLetterFromValue(value) {
        let letter = ''
        if(value > 0) {
            letter = hebTehilim.letters.find(item => value == item.value).letter
        } 
        
        return letter
    },

    getTehilimObj(perekNum) {
        let fullBook = TehilimJSON.tehilim
        let perek = {
            perek: this.convertNumberToLetters(perekNum + 1),
            text: fullBook[perekNum]
        }
        return perek
    },

    getTehilimParts(part) {
        let parts = []
        for(let i = part.start; i <= part.end; i++) {
            if(i == 118) {
                this.getTehilimKYT(part.part).forEach(item => {
                    parts.push(item)
                })
            } else {
                parts.push(this.getTehilimObj(i))
            }
        }
        return parts
    },

    getTehilimKYT(part) {
        let fullBook = TehilimJSON.tehilim
        let KYT = []
        fullBook[118].forEach(item => {
            let firstLetter = item.charAt(0)
            if(!KYT[this.convertLettersToNumber(firstLetter) - 1]) {
                KYT[this.convertLettersToNumber(firstLetter) - 1] = {
                    perek: 'קיט',
                    letter: '',
                    text: []
                }
            }
            KYT[this.convertLettersToNumber(firstLetter) - 1].perek = 'קיט',
            KYT[this.convertLettersToNumber(firstLetter) - 1].letter = firstLetter,
            KYT[this.convertLettersToNumber(firstLetter) - 1].text.push(item)
        });
        
        KYT = KYT.filter(item => {
            return item != null 
        })

        if(part == 1) {
            KYT = KYT.slice(0, 12)
        }else if(part == 2) {
            KYT = KYT.slice(12, KYT.length)
        }

        return KYT
    },

    getTehilimPartsDay(hebDay) {
        return hebTehilim.tehilimDaysMonth[this.convertLettersToNumber(hebDay) - 1]
    },

    getTehilimWeek(day) {
        let part = hebTehilim.tehilimDaysWeek[day]
        let parts = []
        for(let i = part.start; i <= part.end; i++) {
            if(i == 118) {
                this.getTehilimKYT(3).forEach(item => {
                    parts.push(item)
                })         
            } else {
                parts.push(this.getTehilimObj(i))
            }
        }

        return parts
    },

    getTehilimBook(bookNumber) {
        let part = hebTehilim.tehilimBooks[bookNumber]
        let parts = []
        for(let i = part.start; i <= part.end; i++) {
            if(i == 118) {
                this.getTehilimKYT(3).forEach(item => {
                    parts.push(item)
                })         
            } else {
                parts.push(this.getTehilimObj(i))
            }
        }

        return parts
    },

    getPerekTehilim(perekNumber) {
        let parts = []
        if(perekNumber == 118) {
            this.getTehilimKYT(3).forEach(item => {
                parts.push(item)
            })         
        } else {
            parts.push(this.getTehilimObj(perekNumber))
        }
        
        return parts
    },

    getArrOfPerek(start, end) {
        let perekArr = []
        for(let i = start + 1; i <= end; i++) {
            perekArr.push(this.convertNumberToLetters(i))
        }
        return perekArr
    },

    convertBookStrToBookNumber(book) {
        for(let i = 0; i < hebTehilim.booksTitle.length; i++) {
            if(hebTehilim.booksTitle[i] == book) {
                return i
            }
        }
        return 0
    },

    convertDayStrToBookNumber(day) {
        for(let i = 0; i < hebTehilim.daysTitle.length; i++) {
            if(hebTehilim.daysTitle[i] == day) {
                return i
            }
        }
        return 0
    }
}