<template>
    <div>
        <CustomAppbar :title="'תהילים יומי'"/>
        <br/><br/>
        <div class="mt-8 mx-auto px-4" style="max-width:800px;">

            <div class="text-center mt-12" v-if="loading">
                <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate>
                </v-progress-circular>
            </div>
            <div v-else>
                <div class="text-center">
                    <h3>תהילים יומי</h3>
                    <h3 class="primary--text">יום {{ hebDay }} {{ hebDate }}</h3>
                </div>
                
                <div class="mt-6 mb-6" v-for="(item, i) in tlm" :key="i">
                    <div v-if="item.perek != 'קיט'">
                        <h4 class="mt-3">{{ item.perek }}</h4>
                        <div v-for="(txt, j) in item.text" :key="j">{{ txt }}</div>
                    </div>

                    <div v-else>
                        <h4 class="mt-3" v-if="i == 0 || tlm[i-1].perek != 'קיט'">{{ item.perek}}</h4>
                        <h4 class="mt-2">{{ item.letter }}</h4>
                        <div v-for="(txt, h) in item.text" :key="h">{{ txt }}</div>
                    </div>
                </div>

                <ShareTehilimDay/>

                <JoinTelegram class="mt-10" title="לקבלת תזכורת לקריאת תהילים יומי הצטרפו לערוץ הטלגרם שלנו"/>

                <MorePrayers/>

                <v-divider class="mt-8"></v-divider>
                
                <ShareApp/>
                
                <Footer/>
            </div>

        </div>
    </div>
</template>

<script>
const CustomAppbar = () => import('@/components/Appbars/CustomAppbar.vue')
import  tehilim from '@/scripts/tehilim.js'
import  hebTehilim from '@/scripts/heb-tehilim.js'
const ShareTehilimDay = () => import('../components/App/ShareTehilimDay.vue')
const MorePrayers = () => import('../components/MorePrayers.vue')
const ShareApp = () => import('@/components/App/ShareApp.vue')
const Footer = () => import('@/components/App/Footer.vue')
const JoinTelegram = () => import('@/components/JoinTelegram.vue')

export default {
    data() {
        return {
            tlm: '',
            hebDate: '',
            hebDay: '',
            loading: true
        }
    },
    methods: {
        initData() {
            let year = (new Date()).getFullYear()
            let month = (new Date()).getMonth() + 1
            let day = (new Date()).getDate()
            fetch(`https://www.hebcal.com/converter?cfg=json&gy=${year}&gm=${month}&gd=${day}&g2h=1`)
                .then(res => res.clone().json())
                .then(res => {
                    this.hebDate = res.hebrew
                    let dayHeb = res.hebrew.split(" ")[0].replace(/[״']/g, "")
                    dayHeb = dayHeb.replace(/[׳']/g, "")
                    this.tlm = tehilim.getTehilimParts(tehilim.getTehilimPartsDay(dayHeb))
                    this.loading = false
                })
                .catch(() => {

                })
        }
        
    },
    components: {
        CustomAppbar,
        ShareTehilimDay,
        MorePrayers,
        ShareApp,
        Footer,
        JoinTelegram,
    },
    created() {
        this.loading = true
        this.hebDay = hebTehilim.days[(new Date()).getDay()]
        this.initData()
        this.$gtag.pageview(this.$route)
    }
}

</script>